<template lang="pug">
	h1#logo
		| Visibox
		SvgIcon(data="@svgs/visibox-roxa.svg", original)
</template>

<script>
export default {
	name: "component-logo",
}
</script>

<style lang="stylus" scoped src="./Logo.styl"></style>
